[p-color-scheme="light"],
[p-color-scheme="dark"] {
    --p-background: #fff;
    --p-shadow-card: 0px 0px 21px rgba(16, 36, 69, 0.2), 0px 1px 2px rgba(0, 0, 0, 0.15);
    --p-text: #102445;

    --p-surface-neutral: #e4e5e7;
    --pc-progress-bar-indicator: #102445;
    --p-border-highlight: #102445;
    --p-space-14: 3.5rem;
}

.Polaris-RadioButton {
    --p-interactive: #102445;
}

.Polaris-Frame-Toast {
    --p-text: #fff;
}

[p-color-scheme="light"],
[p-color-scheme="dark"],
#root {
    min-height: 100%;
    height: 100%;
}

.Polaris-Heading {
    margin-bottom: var(--p-space-6);
}

.business-plan--annual .Polaris-DisplayText--sizeLarge {
    margin-bottom: 0;
}

.Polaris-Card__Header > .Polaris-Heading {
    margin-bottom: 0;
}

.Polaris-Page {
    padding: 0 var(--p-space-6);
    /* padding: 0 var(--p-space-14) 0 var(--p-space-32) */
}

.Polaris-Stack--vertical,
.Polaris-Page--fullWidth,
.Polaris-Page__Content {
    min-height: 100%;
    height: 100%;
}

.Polaris-Page__Content {
    margin: 0 !important;
}

/* .Polaris-Stack > .Polaris-Stack__Item {
  margin-top: 0;
} */

.lift-up {
    margin-bottom: -8px;
}

.mt-2 {
    margin-top: var(--p-space-2);
}

.mt-4 {
    margin-top: var(--p-space-4);
}

.mt-8 {
    margin-top: var(--p-space-8);
}

.mb-8--negative {
    margin-bottom: calc(var(--p-space-8)*-1);
}

.visible-md {
    display: none;
}

.hidden-md {
    display: block;
}

.d-flex{
    display: flex;
}

.flex-end {
    justify-content: flex-end;
}

.flex-center {
    justify-content: center;
}

.h-100 {
    height: 100%;
}

.Top-Padding--extraSmall {
    padding-top: var(--p-space-4);
}

.Top-Padding--small {
    padding-top: var(--p-space-6);
}

.Top-Padding--medium {
    padding-top: var(--p-space-8);
}

.Top-Padding--large {
    padding-top: var(--p-space-12);
}

.Top-Padding--extraLarge {
    padding-top: var(--p-space-16);
}

.Top-Padding--superLarge {
    padding-top: var(--p-space-20);
}

.Polaris-ProgressBar {
    margin: var(--p-space-3) 0;
}

.text-center {
    text-align: center;
}

.Polaris-DisplayText--sizeExtraLarge,
.Polaris-DisplayText--sizeLarge {
    margin-bottom: var(--p-space-6);
}

.Polaris-DisplayText--sizeSmall,
p {
    margin-bottom: var(--p-space-4);
}

.Polaris-Grid {
    padding-top: var(--p-space-8);
}

.Polaris-Button {
    --p-shadow-button: 0px 1px 0px rgba(0, 0, 0, 0.08), inset 0px -1px 0px rgba(0, 0, 0, 0.2);
}

.Polaris-Button--primary {
    --p-action-primary: #102445;
    --p-text-on-primary: #fff;
    --p-action-primary-hovered: #20437d;
    --p-action-primary-pressed: #20437d;
    --p-action-primary-depressed: #20437d;
}

.Polaris-FormLayout__Item .Polaris-ButtonGroup .Polaris-Button--primary {
    --p-action-primary: #2C6ECB;
    --p-text-on-primary: #fff;
    --p-action-primary-hovered: #2C6ECB;
    --p-action-primary-pressed: #2C6ECB;
    --p-action-primary-depressed: #2C6ECB;
}

.Polaris-Popover {
    padding: var(--p-space-2);
}

.Polaris-Thumbnail {
    --pc-thumbnail-small-size: 2.2rem;
}

.tab-wrapper {
    max-width: 300px;
}
/* .Polaris-Tabs__Wrapper{
    width: auto;
    display: inline-block;
    padding: 0;
    padding-right: 32px;
}
.Polaris-Tabs__TabMeasurer{
    display: none;
} */

a,
a:hover,
a:active {
    color: #102445;
}

.tangiblee-cta-preview {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F4F4F4;
    position: relative;
}

.tangiblee-cta {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: calc((var(--p-line-height-6) - var(--p-line-height-2) - var(--p-space-05))/2) var(--p-space-4);
}

.tangiblee-cta__icon {
    margin-right: 5px;
    margin-top: 3px;
}

.reset-btn {
    background: none;
    border: none;
    border-radius: 0;
    cursor: pointer;
    color: #202223;
    text-decoration: underline;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 16px;
    right: 16px;
}

.reset-btn>img {
    margin-right: var(--p-space-2);
}

.picker-activator {
    border: none;
    padding: var(--p-space-1) var(--p-space-2);
    width: 60px;
    height: 28px;
    background: #FFFFFF;
    box-shadow: -1px 0px 20px rgba(23, 24, 24, 0.05), 0px 1px 5px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.slider-tooltip {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    box-shadow: -1px 0px 20px rgb(23 24 24 / 5%), 0px 1px 5px rgb(0 0 0 / 15%);
    border-radius: 4px;
}

.business-plan {
    /* width: 228px; */
    width: 260px;
    margin: auto;
}

.business-plan > .Polaris-Card > .Polaris-Card__Section {
    padding: 0;
}

.support>div {
    display: inline-block;
}

.welcome-picture{
    max-width: 100%;
}

.back-url a{
    color: #8C9196 !important;
    display: flex;
    align-items: center;
    padding: var(--p-space-2);
}

.back-url a img{
    margin-right: 5px;
    width: 20px;
    height: auto;
}

.steps li {
    color: #6D7175;
}

.steps li.active {
    color: #102445;
}

.info-panel {
    border-radius: 8px;
    background: #F2F3F6;
    padding: var(--p-space-8);
}

.old-price {
    font-size: 16px;
    text-decoration: line-through;
}

.info-panel p {
    display: flex;
    align-items: center;
    margin: 0 0 var(--p-space-5) 0;
}
.info-panel p:last-child {
    margin: 0;
}

.info-panel p img {
    margin-right: var(--p-space-5);
}

.mw-480 {
    max-width: 480px;
}

.mx-auto {
    margin: auto;
}


.button-template {
    width: 84px;
    height: 25px;
    background-color: #000000;
    border-radius: 2px;
}

.button-template.button-template--tng {
    width: 68px;
    background-color: #008060;
}

.Polaris-PositionedOverlay {
    background-color: #fff;
}

@media (min-width: 768px) {
    .visible-md {
        display: block;
    }
    
    .hidden-md {
        display: none;
    }

    .Polaris-Page {
        padding: 0 var(--p-space-14) 0 var(--p-space-20)
    }

    .business-plan {
        margin: 0;
    }
}